import * as React from 'react'
import Layout from '@/components/Layout'
import SEO from '@/components/seo'
import { useLocation } from '@reach/router'
import Breadcrumbs from '@/components/Common/Breadcrumbs'
import SdetailComp from '@/components/Services/Details'

const SErviceDetails = ({ location }: any) => {
  const { state } = location
  return (
    <Layout>
      <SEO title="Services Details" canonical={`${useLocation().host}`} />
      <Breadcrumbs title="Services Details" />
      <SdetailComp activeServ={state ? state.showServ : ''} />
    </Layout>
  )
}

export default SErviceDetails
