import { graphql, useStaticQuery } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import Image from '@/components/Image'
export const filterServices = (
  services: allServicesProps[],
  searchQuery: string,
): allServicesProps[] => {
  if (!services) {
    return []
  }
  if (!searchQuery && searchQuery.length === 0) {
    return services
  }

  const normalizeString = (sentence: string) => {
    return sentence.toLowerCase()
  }

  return services.filter(
    ({ node }) =>
      normalizeString(node.name).includes(normalizeString(searchQuery)) ||
      normalizeString(node.slug).includes(normalizeString(searchQuery)) ||
      normalizeString(node.description).includes(normalizeString(searchQuery)) ||
      normalizeString(node.descriptionDetail.join(' ')).includes(normalizeString(searchQuery)) ||
      normalizeString(node.descriptionIntro.join(' ')).includes(normalizeString(searchQuery)),
  )
}
type allServicesProps = {
  node: {
    name: string
    icon: string
    description: string
    descriptionIntro: string[]
    descriptionDetail: string[]
    slug: string
    image1: string
    image2: string
    image3: string
    faqs: FaqProps[]
  }
}
type FaqProps = {
  question: string
  answer: string
}

const SdetailComp = ({ activeServ }: any) => {
  const data = useStaticQuery(graphql`
    query {
      allServicesJson {
        edges {
          node {
            name
            icon
            description
            descriptionIntro
            descriptionDetail
            slug
            image1
            image2
            image3
            faqs {
              question
              answer
            }
          }
        }
      }
    }
  `)
  const [query, setQuery] = useState<string>('')
  const [service, setService] = useState<allServicesProps>(data.allServicesJson.edges[0].node)
  const [filteredServices, setFilteredServices] = useState<allServicesProps[]>(
    data.allServicesJson.edges,
  )

  useEffect(() => {
    if (activeServ) {
      let filter = []
      if (data.allServicesJson.edges.length === filteredServices.length) {
        filter = data.allServicesJson.edges.filter(({ node }: any) => node.name === activeServ)
      } else {
        return setService(filteredServices[0])
      }
      return setService(filter[0])
    }
    navigate('/services')
  }, [filteredServices])

  const handleSubmit = (e) => {
    e.preventDefault()
    setFilteredServices(filterServices(data.allServicesJson.edges, query))
  }
  return (
    <div className="service-details-section margin-top-120" style={{ marginBottom: '120px' }}>
      <div className="container nav-container">
        <div className="row">
          <div className="col-lg-4">
            <div className="widget-area">
              <div className="widget widget_search">
                <form onSubmit={handleSubmit} className="search-form">
                  <div className="form-group">
                    <input
                      value={query}
                      onChange={(e) => setQuery(e.target.value)}
                      type="text"
                      className="form-control"
                      placeholder="Search"
                    />
                  </div>
                  <button className="submit-btn" type="submit">
                    <i className="fa fa-search"></i>
                  </button>
                </form>
              </div>
              <div className="widget widget_categories">
                <h3 className="widget-headline">Service Category</h3>
                <ul>
                  {filteredServices &&
                    filteredServices.map(({ node }) => (
                      <li
                        key={node.slug}
                        className={
                          service && service.node && service.node.name === node.name ? 'active' : ''
                        }
                        onClick={() => setService({ node: { ...node } })}
                      >
                        {node.name}
                        <i className="fas fa-angle-right"></i>
                      </li>
                    ))}
                </ul>
              </div>
              <div className="widget widget_contacts">
                <div className="title">Contact Us</div>
                <p>We are here to support you. Call us anytime! </p>
                <span>
                  <i className="fas fa-phone"></i>
                  <a href="tel:13058233561">+1 305-823-3561</a>
                </span>
              </div>
            </div>
          </div>
          {service && service.node ? (
            <div className="col-lg-8">
              {' '}
              <div className="thumbnail big">
                <Image name={service && service.node && service.node.image1.split('/')[4]} />
                {/* <img src={service && service.node && service.node.image1} alt="img" /> */}
              </div>
              <h2 className="title">{service && service.node && service.node.name}</h2>
              <div className="content">
                {service &&
                  service.node &&
                  service.node.descriptionIntro.map((desc, i) => <p key={i}>{desc}</p>)}
              </div>
              <div className="content">
                <h2 className="title">What You Will Get From Us</h2>
                {service &&
                  service.node &&
                  service.node.descriptionDetail.map((desc, i) => <p key={i}>{desc}</p>)}
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="thumbnail">
                    <Image name={service && service.node && service.node.image2.split('/')[4]} />

                    {/* <img src={service && service.node && service.node.image2} alt="img" /> */}
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="thumbnail">
                    <Image name={service && service.node && service.node.image3.split('/')[4]} />

                    {/* <img src={service && service.node && service.node.image3} alt="img" /> */}
                  </div>
                </div>
              </div>
              <div className="content">
                <h2 className="title">{`Frequently asked questions.`}</h2>
              </div>
              <div className="accordion-wrapper">
                <div className="accordion mt-10" id="accordionExample">
                  {service &&
                    service.node &&
                    service.node.faqs.map((e: FaqProps, inx: number) => (
                      <div key={inx} className="card">
                        <div className="card-header" id={`heading${inx.toString()}`}>
                          <h5 className="mb-0">
                            <a
                              className="collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#faqs-${inx.toString()}`}
                              aria-expanded="true"
                              aria-controls={`faqs-${inx.toString()}`}
                            >
                              {e.question}
                            </a>
                          </h5>
                        </div>
                        <div
                          id={`faqs-${inx.toString()}`}
                          className={`${inx === 0 ? 'collapse show' : 'collapse'}`}
                          aria-labelledby={`heading${inx.toString()}`}
                          data-bs-parent="#accordionExample"
                        >
                          <div className="card-body">{e.answer}</div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ) : (
            <div className="col-lg-8"></div>
          )}
        </div>
      </div>
    </div>
  )
}

export default SdetailComp
